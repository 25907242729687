import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { brand } from 'src/app/core/interface/brand';
import { category } from 'src/app/core/interface/category';
import { AppState } from 'src/app/store/app.store';
import { getBrandsData } from 'src/app/store/catalog/brands/brand.selectors';
import { getCategoriesData } from 'src/app/store/catalog/categories/category.selectors';
import { FilterActions } from 'src/app/store/filter/filter.actions';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  brandsList: brand[] = [];
  categoriesList: category[] = [];
  filteredBrands: brand[] = [];
  filteredCategories: category[] = [];
  search_query = '';
  min_price = 0;
  max_price = 10000;
  params: any;
  newParams: any;
  brands: number[] = [];
  categories: number[] = [];
  showBrandFilter = true;

  brandCollapse = true;
  catCollapseShow: {
    header: boolean,
    parent: boolean[],
    child: boolean[]
  } = {
      header: true,
      parent: [],
      child: []
    };

  search_brand = '';
  search_category = '';

  constructor(
    private _activeRoute: ActivatedRoute,
    private _store: Store<AppState>,
    private _breakpointObserver: BreakpointObserver,
    @Optional() private _dialogRef: MatDialogRef<FilterComponent>,
  ) { }

  applyFilter() {
    this.newParams = {
      search_query: this.search_query.replace(/[^a-zA-Z0-9 ]/g, ''),
      max_price: this.max_price,
      min_price: this.min_price,
      brand: this.brands.toString(),
      category: this.categories.toString(),
      page: 1
    }
    this._store.dispatch(FilterActions.updateParams({ params: this.newParams }));
  }

  brandUpdate(e: any, id: number) {
    if (e.target.checked) {
      this.brands.push(id);
    } else {
      this.brands = this.brands.filter(v => v !== id);
    }
    this.applyFilter();
  }

  clickCategory(id: number) {
    document.getElementById('category_' + id)?.click();
  }

  categoryUpdate(e: any, id: number) {
    if (e.target.checked) {
      this.categories.push(id);
    } else {
      this.categories = this.categories.filter(v => v !== id);
    }
    this.applyFilter();
  }

  closeDialog() {
    this._dialogRef?.close();
  }

  refetchData() {
    setTimeout(() => this.ngOnInit(), 1000);
  }

  ngOnInit(): void {
    this._activeRoute.params.subscribe(
      (params: any) => {
        this.brands = [];
        this.categories = [];
        if (params.type == 'brand') {
          this.brands.push(params.id);
          this.showBrandFilter = false;
        }
        if (params.type == 'category') {
          this.categories.push(params.id);
          this.showBrandFilter = true;
        }
      }
    )
    this._breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletPortrait]).subscribe(
      res => {
        if (res.matches) {
          this.catCollapseShow.header = false;
        }
      }
    )
    this.catCollapseShow.parent.fill(true);
    this.catCollapseShow.child.fill(true);
    this._store.select(getBrandsData).subscribe(
      brands => this.brandsList = brands?.brands
    );
    this._store.select(getCategoriesData).subscribe(
      categories => categories ? this.categoriesList = categories : this.refetchData()
    );
    this.filterBrands();
    this.filterCategories();
    this._activeRoute.queryParams.subscribe(
      (qparams: any) => {
        if (qparams.max_price) {
          this.max_price = qparams.max_price
        }
        if (qparams.min_price) {
          this.min_price = qparams.min_price
        }
        if (qparams.search_query) {
          this.search_query = qparams?.search_query
        }
      }
    )
  }


  filterBrands() {
    if (this.search_brand) {
      this.filteredBrands = this.brandsList.filter(v => v.title.toLowerCase().includes(this.search_brand));
    } else {
      this.filteredBrands = this.brandsList;
    }
  }

  filterCategories() {
    if (this.search_category) {
      this.filteredCategories = this.categoriesList.filter(v => v.title.toLowerCase().includes(this.search_category));
    } else {
      this.filteredCategories = this.categoriesList;
    }
  }
}
