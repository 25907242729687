<div class="category-banner" *ngIf="banner$ | async as banners">
  <img loading="lazy"  *ngIf="(isHandset$ | async) && banners.mobile" [src]="banners.mobile" alt="Buy Makeup Product Online | Foreveryng">
  <img loading="lazy"  *ngIf="(isHandset$ | async) === false && banners.web" [src]="banners.web" alt="Buy Makeup Product Online | Foreveryng">
  <div class="brand_default_banner" *ngIf="! banners.web && (brandImage$ | async)">
    <div class="container">
      <div class="wrap_brand_container">
        <div class="brand_logo">
         <img loading="lazy"  [src]="brandImage$ | async" alt="Buy Makeup Product Online | Foreveryng">
        </div>
        <div class="brand_slogan">
          <img loading="lazy"  src="../../../assets/images/banner-slogan.png" alt="Buy Makeup Product Online | Foreveryng">
        </div>
        <div class="rightside_elements">
          <img loading="lazy"  src="../../../assets/images/brandbanner-elements.png" alt="Buy Makeup Product Online | Foreveryng">
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="combos$ | async as combos">
  <div class="combos_products" *ngIf="combos.length > 0">
    <div class="container">
      <h3>Combos Products</h3>
      <div class="product-list-combowrappers">
        <div class="product" *ngFor="let product of combos">
          <app-product-card [productData]="product"></app-product-card>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<div class="wrap_filters_sections">
  <div class="container_filterwrap">
    <div class="wrap_filters_blocks">
      <div class="filter-pop" *ngIf="isHandset$ | async">
        <button mat-fab tabindex="0" (click)="popFilter()" (keypress)="popFilter()">
          <mat-icon class="mat-18">filter_list</mat-icon>
        </button>
      </div>
      <div class="filter-divison" *ngIf="(isHandset$ | async) === false">
        <div class="filter-container">
          <div class="filter-sticky">
            <app-filter></app-filter>
          </div>
        </div>
      </div>
      <div class="products-divison">
        <div class="header" *ngIf="pagination$ | async as pagination">
          <div class="filter-title">
            {{title | titlecase}}
          </div>
          <div class="products-count" *ngIf="(isHandset$ | async) === false">
            Total Products: {{pagination.total}}
          </div>
          <div class="products-sort">
            <select [(ngModel)]="sortValue" (change)="sortFilter(sortValue-1)">
              <option [value]="value.id" *ngFor="let value of sortValues">{{value.name}}</option>
            </select>
          </div>
        </div>
        <div class="products-count" *ngIf="isHandset$ | async">
          Total Products: {{(pagination$ | async)?.total}}
        </div>
        <div class="products-container" style="position: relative;">
          <div class="filter_updates_loader" *ngIf="isLoading">
            <div class="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <ng-container *ngFor="let product of products$ | async">
            <app-product-card [productData]="product" [variation]="'second_product_card'"></app-product-card>
          </ng-container>
        </div>
        <ng-container *ngIf="pagination$ | async as pagination">
          <div class="pagination" *ngIf="pagination.lastPage > 1" [ngStyle]="{ 'margin-bottom': (isHandset$ | async) ? '113px' : '' }">
            <div class="number_list">
              <div [ngClass]="[pagination.currentPage === 1 ? 'active' : '']" class="number"
                tabindex="0" (click)="loadPage(1)" (keypress)="loadPage(1)">
                1
              </div>
              <div class="dot" *ngIf="pagination.currentPage - 4 > 1">
                ...
              </div>
              <ng-container *ngFor="let number of [].constructor(pagination.lastPage-1); index as i">
                <div [ngClass]="[pagination.currentPage === i+1 ? 'active' : '']" *ngIf="i !== 0 && i+1 <= pagination.currentPage + 3 && i+1 >= pagination.currentPage - 3" class="number"
                tabindex="0" (click)="loadPage(i+1)" (keypress)="loadPage(i+1)">
                  {{i+1}}
                </div>
              </ng-container>
              <div class="dot" *ngIf="pagination.lastPage - pagination.currentPage > 4">
                ...
              </div>
              <div [ngClass]="[pagination.currentPage === pagination.lastPage ? 'active' : '']" class="number"
                tabindex="0" (click)="loadPage(pagination.lastPage)" (keypress)="loadPage(pagination.lastPage)">
                {{ pagination.lastPage }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>