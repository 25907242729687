<form (ngSubmit)="handleClick({ attribute: 'filter', filter: { 'search_query': searchValue, 'title': searchValue } })">

    <div class="autosearch_form_fields">
      <div style="display:flex;width:100%;gap:10px;margin-bottom: 5px;">
        <button *ngIf="(isHandset$ | async)" (click)="closeAutoSearch()" class="autosearch-close-btn"><</button>
        <input class="search-input" type="text" [(ngModel)]="searchValue" [ngModelOptions]="{standalone: true}">
      </div>
        <div class="banner">
            <ng-container *ngIf="suggestionsData.webBanner">
                <a href="javascript:void(0)" (click)="handleClick(suggestionsData.webBanner)" *ngIf="(isHandset$ | async) === false">
                    <img [src]="suggestionsData.webBanner.image" [alt]="" width="100%">
                </a>
            </ng-container>
            <ng-container *ngIf="suggestionsData.banner">
                <a href="javascript:void(0)" (click)="handleClick(suggestionsData.banner)" *ngIf="isHandset$ | async">
                    <img [src]="suggestionsData.banner.image" [alt]="" width="100%">
                </a>
            </ng-container>
        </div>
        <div class="search_suggestions">
            <span CLAS *ngFor="let search of searchData?.suggestions"
                tabindex="0" (click)="handleClick({ attribute: 'filter', filter: { 'search_query' : search, 'title': search } })" (keypress)="handleClick({ attribute: 'filter', filter: { 'search_query' : search, 'title': search } })">
                <mat-icon>search</mat-icon>
                {{search}}
            </span>
        </div>
    </div>
</form>
<div class="topsearchs_secauto_search">
    <div *ngIf="searchData && searchValue !==''">
        <div class="topsearch_blocks">
            <span *ngFor="let search of searchData?.brands"
                tabindex="0" (click)="handleClick({ attribute: 'brand', 'id' : search.id, 'title': search.title, slug: search.slug })" (keypress)="handleClick({ attribute: 'brand', 'id' : search.id, 'title': search.title, slug: search.slug })">
                <img loading="lazy"  [src]="search.image" style="max-width: 60px; max-height: 60px; object-fit: contain;" alt="search">
            </span>
        </div>
        <div class="wrap_product_suggestions">
            <div *ngFor="let product of searchData?.products" class="product_suggestions"
                tabindex="0" (click)="handleClick({  attribute: 'product', 'id' : product.id, 'title': product.title, slug: product.slug })" (keypress)="handleClick({  attribute: 'product', 'id' : product.id, 'title': product.title, slug: product.slug })">
                <img loading="lazy"  [src]="product.coverImage" alt="{{product.name}}">
                <h3>{{product.name}}</h3>
            </div>
        </div>
    </div>



    <div class="topbrands_autosearch">
        <div class="topsearch_autosearch">
            <h2>Top Search</h2>
            <div class="blocks_topsearch">
                <span *ngFor="let search of suggestionsData?.recommendedSearch"
                    tabindex="0" (click)="handleClick({ attribute: 'filter', filter: { 'search_query' : search, 'title': search } })" (keypress)="handleClick({ attribute: 'filter', filter: { 'search_query' : search, 'title': search } })">
                    {{search}}</span>
            </div>
        </div>

        <div class="topsearch_autosearch">
            <h2>Top Brands</h2>
            <div class="blocks_topsearch">
                <span *ngFor="let search of suggestionsData?.topBrands"
                    tabindex="0" (click)="handleClick({ attribute: 'brand', 'id' : search.id, 'title': search.title, slug: search.slug })" (keypress)="handleClick({ attribute: 'brand', 'id' : search.id, 'title': search.title, slug: search.slug })">
                    {{search.title}}</span>
            </div>
        </div>

        <div class="topsearch_autosearch">
            <h2>Top Categories</h2>
            <div class="blocks_topsearch">
                <span *ngFor="let search of suggestionsData?.topCategories"
                    tabindex="0" (click)="handleClick({ attribute: 'category', 'id' : search.id, 'title': search.title, slug: search.slug })" (keypress)="handleClick({ attribute: 'category', 'id' : search.id, 'title': search.title, slug: search.slug })">
                    {{search.title}}</span>
            </div>
        </div>
    </div>
</div>
