<div class="filter-container">
    <div class="keyword-filter">
        <input type="text" placeholder="Enter Keyword" (keydown.enter)="closeDialog()" [(ngModel)]="search_query" (change)="applyFilter()">
    </div>
    <div class="price-filter">
        <div class="title">Price Range</div>
        <div class="range">{{ min_price | nepaliCurrency }} - {{ max_price | nepaliCurrency }}</div>
        <div class="slider">
            <mat-slider min="0" max="50000" discrete="false" color="accent">
                <input [(value)]="min_price" matSliderStartThumb (change)="applyFilter()">
                <input [(value)]="max_price" matSliderEndThumb (change)="applyFilter()">
            </mat-slider>
        </div>
    </div>
    <div class="category-filter">
        <div class="title" tabindex="0" (click)="catCollapseShow.header = ! catCollapseShow.header" (keypress)="catCollapseShow.header = ! catCollapseShow.header">
            Filter by Categories 
            <mat-icon class="mat-18" *ngIf="! catCollapseShow.header">expand_more</mat-icon>
            <mat-icon class="mat-18" *ngIf="catCollapseShow.header">expand_less</mat-icon>
        </div>
        <div class="content" *ngIf="catCollapseShow.header">
            <input type="text" placeholder="Filter category" [(ngModel)]="search_category"
                (keypress)="filterCategories()">
            <div class="list">
                <div class="category" *ngFor="let category of filteredCategories?.slice(0, 9)">
                    <div class="label_checkbox_sec" tabindex="0" (click)="catCollapseShow.parent[category.id] = ! catCollapseShow.parent[category.id]" (keypress)="catCollapseShow.parent[category.id] = ! catCollapseShow.parent[category.id]">
                        <label [for]="'category_'+category.id" tabindex="0" (click)="clickCategory(category.id)" [ngClass]="categories.includes(category.id) ? 'active' : ''" (keypress)="clickCategory(category.id)" [ngClass]="categories.includes(category.id) ? 'active' : ''">
                            {{category.title}}
                            <ng-container *ngIf="category.children.length === 0">
                                <input [id]="'category_'+category.id" type="checkbox" (change)="categoryUpdate($event, category.id)">
                                <span></span>
                            </ng-container>
                        </label>
                        
                        <ng-container *ngIf="category.children.length > 0">
                            <mat-icon class="mat-18" *ngIf="! catCollapseShow.parent[category.id]">expand_more</mat-icon>
                            <mat-icon class="mat-18" *ngIf="catCollapseShow.parent[category.id]">expand_less</mat-icon>
                        </ng-container>
                    </div>
                    <div class="category-children-list" *ngIf="catCollapseShow.parent[category.id]">
                        <div class="category-child" *ngFor="let child of category.children">
                            <div class="label_checkbox_sec" tabindex="0" (click)="catCollapseShow.child[child.id] = ! catCollapseShow.child[child.id]" (keypress)="catCollapseShow.child[child.id] = ! catCollapseShow.child[child.id]">
                                <label [for]="'category_'+child.id" tabindex="0" (click)="clickCategory(child.id)" [ngClass]="categories.includes(child.id) ? 'active' : ''" (keypress)="clickCategory(child.id)" [ngClass]="categories.includes(child.id) ? 'active' : ''">
                                    {{child.title}}
                                    <ng-container *ngIf="child.children.length === 0">
                                        <input [id]="'category_'+child.id" type="checkbox" (change)="categoryUpdate($event, child.id)">
                                        <span></span>
                                    </ng-container>
                                </label>
                                <ng-container *ngIf="child.children.length > 0">
                                    <mat-icon class="mat-18" *ngIf="! catCollapseShow.child[child.id]">expand_more</mat-icon>
                                    <mat-icon class="mat-18" *ngIf="catCollapseShow.child[child.id]">expand_less</mat-icon>
                                </ng-container>
                            </div>

                            <div class="category-grand-children-list" *ngIf="catCollapseShow.child[child.id]">
                                <div class="label_checkbox_sec" *ngFor="let grandChild of child.children">
                                    <label [for]="'category_'+grandChild.id" tabindex="0" (click)="clickCategory(grandChild.id)" [ngClass]="categories.includes(grandChild.id) ? 'active' : ''" (keypress)="clickCategory(grandChild.id)" [ngClass]="categories.includes(grandChild.id) ? 'active' : ''">
                                        {{grandChild.title}}
                                        <input
                                        [id]="'category_'+grandChild.id" type="checkbox"
                                        (change)="categoryUpdate($event, grandChild.id)">
                                        <span></span>

                                    </label> 
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="category-filter brand_filter" *ngIf="showBrandFilter">
        <div class="title" tabindex="0" (click)="brandCollapse = ! brandCollapse" (keypress)="brandCollapse = ! brandCollapse">
            Filter by Brand 
            <mat-icon class="mat-18" *ngIf="brandCollapse">expand_more</mat-icon> 
            <mat-icon class="mat-18" *ngIf="! brandCollapse">expand_less</mat-icon>
        </div>
        <div class="content" *ngIf="! brandCollapse">
            <input type="text" placeholder="Filter brand" [(ngModel)]="search_brand" (keypress)="filterBrands()">
            <div class="list">
                <div class="category" *ngFor="let brand of filteredBrands.slice(0, 12)">
                    <div class="label_checkbox_sec">
                        <label [for]="'brand_'+brand.id" [ngClass]="categories.includes(brand.id) ? 'active' : ''">
                            {{brand.title}}
                            <input [id]="'brand_'+brand.id" type="checkbox"
                            (change)="brandUpdate($event, brand.id)">
                            <span></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>