import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../app.store';
import { filterFeatureKey } from './filter.reducer';


const getFilter = createFeatureSelector<AppState>(filterFeatureKey);

export const getFilterParams = createSelector(
    getFilter,
    (state: any) => {
        return state.params;
    }
)

export const getFilterProducts = createSelector(
    getFilter,
    (state: any) => {
        return state.products
    }
)


export const getFilterCombos = createSelector(
    getFilter,
    (state: any) => {
        return state.combos
    }
)

export const getFilterBanner = createSelector(
    getFilter,
    (state: any) => state.banner
)

export const proceedFilterUpdate = createSelector(
    getFilter,
    (state: any) => state.hasMoreProducts
)

export const getFilterStatus = createSelector(
    getFilter,
    (state: any) => state.status
)

export const getFilterPagiantion = createSelector(
    getFilter,
    (state: any) => state.pagination
)
