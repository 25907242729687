import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, map, shareReplay } from 'rxjs';
import { AppState } from 'src/app/store/app.store';
import { FilterActions } from 'src/app/store/filter/filter.actions';
import { getFilterBanner, getFilterCombos, getFilterPagiantion, getFilterProducts, getFilterStatus } from 'src/app/store/filter/filter.selectors';
import { FilterComponent } from './filter/filter.component';
import { product } from 'src/app/core/interface/product';
import { SlugService } from 'src/app/core/services/slug.service';
import { Title } from '@angular/platform-browser';
import { getBrandsData } from 'src/app/store/catalog/brands/brand.selectors';
import { brand } from 'src/app/core/interface/brand';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  products$: Observable<product[]> = EMPTY;
  combos$: Observable<product[]> = EMPTY;
  title = '';
  isLoading = true;
  productLength = 0;
  pagination$: Observable<any> = EMPTY;
  brandImage$: Observable<string> = EMPTY;
  banner$: Observable<any> = EMPTY;
  sortValues: { id: number, param: { sorting_key: string, sorting_direction: string }, name: string }[] = [
    { id: 1, param: { sorting_key: 'total_review', sorting_direction: 'DESC' }, name: 'Relevence' },
    { id: 2, param: { sorting_key: 'avg_rating', sorting_direction: 'DESC' }, name: 'Top Rated' },
    { id: 3, param: { sorting_key: 'price', sorting_direction: 'ASC' }, name: 'Price Low to High' },
    { id: 4, param: { sorting_key: 'price', sorting_direction: 'DESC' }, name: 'Price High to Low' },
    { id: 5, param: { sorting_key: 'discount_percent', sorting_direction: 'DESC' }, name: 'Discount % High to Low' }
  ]

  sortValue = 1;

  isHandset$: Observable<boolean> = this._breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _activeRoute: ActivatedRoute,
    private _store: Store<AppState>,
    private _matDialog: MatDialog,
    private _slug: SlugService,
    private _title: Title
  ) { }

  ngOnInit() {
    this._activeRoute.params.subscribe(
      (params: any) => {
        this.title = this._slug.makeTitle(params.slug);
        this._title.setTitle(this.title?.toUpperCase() + ' | Foreveryng');
        window.fbq('track', 'ViewContent', {
          content_type: params.type ? params.type : 'filter',
          content_name: params.slug ? this._slug.makeTitle(params.slug) : 'not_found'
        });
        if (params.type == 'category') {
          this._store.dispatch(FilterActions.setNewParams({ params: { 'category': params.id } }));
        } else if (params.type == 'brand') {
          this._store.dispatch(FilterActions.setNewParams({ params: { 'brand': params.id } }));
          this.brandImage$ = this._store.select(getBrandsData).pipe(map((v: any) => v.brands.filter(function (brand: brand) {
            if (brand.id.toString() === params.id) {
              return true;
            } else {
              return false;
            }
          })[0].image));
        } else {
          this._activeRoute.queryParams.subscribe(
            (qparams: any) => {
              this._store.dispatch(FilterActions.setNewParams({ params: qparams }));
              this.title = qparams.title;
              this._title.setTitle(this.title?.toUpperCase() + ' | Foreveryng');
            }
          )
        }
      }
    )
    this.pagination$ = this._store.select(getFilterPagiantion);
    this.products$ = this._store.select(getFilterProducts);
    this.combos$ = this._store.select(getFilterCombos);
    this.banner$ = this._store.select(getFilterBanner);
    this._store.select(getFilterStatus).subscribe(
      status => {
        if (status == 'success') {
          this.isLoading = false;
        } else {
          this.isLoading = true;
        }
      }
    )
  }

  popFilter() {
    this._matDialog.open(FilterComponent, {
      maxWidth: '95vw',
      width: '95vw',
    });
  }

  sortFilter(id: number) {
    this._store.dispatch(FilterActions.updateParams({ params: { sorting_key: this.sortValues[id].param.sorting_key, sorting_direction: this.sortValues[id].param.sorting_direction, page: 1 } }))
  }

  loadPage(i: number) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this._store.dispatch(FilterActions.updateParams({ params: { page: i } }));
  }
}