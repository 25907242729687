import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SearchRoutingModule } from './search-routing.module';
import { SearchComponent } from './search.component';
import { AutoSearchComponent } from './auto-search/auto-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FilterComponent } from './filter/filter.component';


@NgModule({
  declarations: [
    SearchComponent,
    AutoSearchComponent,
    FilterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    SharedModule,
    SearchRoutingModule,
  ],
  exports: [
    AutoSearchComponent,
  ]
})
export class SearchModule { }
