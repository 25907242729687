import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const FilterActions = createActionGroup({
  source: 'Filter',
  events: {
    'Set New Params': props<{ params: any }>(),
    'Set New Data': props<{ data: any }>(),
    'Update Params': props<{ params: any }>(),
  }
});
