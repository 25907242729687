import { createReducer, on } from '@ngrx/store';
import { FilterActions } from './filter.actions';

export const filterFeatureKey = 'filter';

export interface State {
  params: any,
  banner: any,
  products: any,
  combos: any,
  pagination: any,
  hasMoreProducts: boolean,
  status: string
}

export const initialState: State = {
  params: null,
  banner: null,
  products: null,
  combos: null,
  pagination: null,
  hasMoreProducts: true,
  status: 'pending'
};

export const reducer = createReducer(
  initialState,
  on(
    FilterActions.setNewParams,
    (state, action) => {
      return {
        ...state,
        status: 'loading',
        params: action.params,
        combos: null,
        banner: null
      }
    }
  ),
  on(
    FilterActions.setNewData,
    (state, action) => {
      return {
        ...state,
        banner: {
          web: action.data.web_banner,
          mobile: action.data.banner
        },
        combos: action.data.combos,
        products: action.data.products,
        pagination: action.data.pagination,
        status: 'success',
        params: {
          ...state.params,
          page: action.data.pagination.currentPage
        }
      }
    }
  ),
  on(
    FilterActions.updateParams,
    (state, action) => {
      return {
        ...state,
        status: 'loading',
        params: {
          ...state.params,
          ...action.params
        }
      }
    }
  )
);

